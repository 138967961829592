import {Button, Form, Input, Modal, PageHeader, Result, Table} from "antd";
import React, {useCallback, useEffect, useState} from "react"

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {useForm} from "antd/lib/form/Form";
import {Config} from "../config";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function Home() {

    const [form] = useForm();
    const [linkMeta, setLinkMeta] = useState(null);
    const [documentSent, setDocumentSent] = useState(false);

    // Second, gather session data, if the user is not logged in, redirect to login
    useEffect(async () => {
        let metaShortLink = await loadMetaShortLink();

        if (metaShortLink) {
            setLinkMeta(metaShortLink);
        }

        const queryString = document.location.search.substring(1);
        const query = parseQueryString(queryString);
        const metaf = query.get('metaf');

        if (metaShortLink?.meta.contact_uid || metaf) {
            let a = document.createElement('script');
            let m = document.getElementsByTagName('script')[0];
            m.parentNode.insertBefore(a, m)

            a.async = 1;
            a.src = 'tag.js';
            a.onload = async () => {
                console.log('loaded')
            };
        }
    }, [])

    const handleOk = async () => {

        fetch(Config.apiUrl + '/pipeline/documents/' + linkMeta['meta']['document_uid'] + '/share', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(form.getFieldsValue())
        }).then(() => {
            setDocumentSent(true);
        })
    }

    let parseQueryString = (query) => {
        const params = new Map();

        for (const [key, value] of new URLSearchParams(query)) {
            params.set(key.toLowerCase(), value);
        }

        return params;
    }

    let loadMetaShortLink = async () => {
        const queryString = document.location.search.substring(1);
        const query = parseQueryString(queryString);
        const link = query.get('link');

        if (!link) {
            return;
        }

        return await fetch(Config.apiUrl + '/pipeline/short_link/' + link + '/public').then((resp) => {
            return resp.json();
        })
    }

    if (linkMeta !== null && linkMeta.meta.contact_uid) {
        return (<></>)
    }

    if (linkMeta === null) {
        return (<></>)
    }

    let modal = null;
    if (documentSent) {
        modal = (
            <Modal visible={true}  closable={false} footer={[]}>
                <Result
                    status="success"
                    title="La document a été envoyé!"
                    subTitle="Vous allez recevoir dans quelques instants un email contenant votre lien d'accès personnel au document. Vous pouvez fermer cette fenètre."
                    extra={[]}
                />
            </Modal>
        )
    } else {
        modal = (
            <Modal title="Document protégé" visible={true} onOk={handleOk} footer={[
                <Button key="submit" type="primary" loading={false} onClick={handleOk}>
                    Débloquer
                </Button>]}>
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Form.Item label="Email pro" name={"email"} required tooltip="This is a required field">
                        <Input type={'email'} placeholder="ex : leo@myoffice.com"/>
                    </Form.Item>
                    <Form.Item label="Prénom" name={'firstname'} required tooltip="This is a required field">
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Nom" name={'lastname'} required tooltip="This is a required field">
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }


    return (
        <>
            <div style={{
                backgroundColor: "white",
                position: "fixed",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
            }}>
                {modal}
            </div>
        </>
    )
}

export default Home;

